
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onActivated,
  onMounted,
  nextTick,
  reactive,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getTeacherInfo,
  getSchoolClassName,
  uploadData,
  getKefuWechatQRCode,
  checkPcTeachAuthorization,
  getClientInfo,
  deviceLogin,
  getDeviceInfo,
  getCurrentActiveLesson,
} from "@/services";
import store, { MutationNames } from "@/store/index";
import JsBridge from "../../jsBridge";
import servers from "@/config/servers";
import config from "@/config/app.config";
import {
  eventCenter,
  event,
  usePageTableContext,
} from "@evideo/frontend-utils";
import pageTableContextMap from "@/utils/page-table-context-map";
import HomeBackground from "@/components/common/HomeBackground";
import _ from "lodash";
import logger from "@evideo/logger";
import SoftwareControlBar from "@/components/softwareControlBar.vue";

export default defineComponent({
  components: { SoftwareControlBar, HomeBackground },
  setup() {
    const store = useStore();
    let isShow = ref(store.state.firstLoadHome);
    let confirmQuitFlag = ref(false);
    // const mask = ref(null);
    // const isShow = ref(null);
    const close = ref(null);
    // let gradeNum = ref("几年级");
    // let classNum = ref("几班");
    let gradeNum = ref(store.state.gradeNumber);
    let classNum = ref(store.state.classNumber);
    let moduleName = ref(store.state.minimizeModuleName);
    const { deleteContextInfo } = usePageTableContext();
    let getSchoolClassId = ref("");
    const getLable = ref("");
    const getLableNext = ref("");
    const getIndexValue = ref("");
    let getClassData = reactive();
    const options = reactive([]);
    const gradeOptions = ref();
    const classOptions = ref();
    const options_next = reactive([]);
    let selGrade = ref(store.state.gradeName);
    let selClass = ref(store.state.className);
    let selGradeName = ref("");
    let selClassName = ref("");
    let getResourceEmptyUrl = ref("");
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });

    const value = ref("");
    const value_next = ref("");
    const getDataGrade = reactive([]);
    const chineseNum = reactive([
      "一",
      "二",
      "三",
      "四",
      "五",
      "六",
      "七",
      "八",
      "九",
      "高一",
      "高二",
      "高三",
    ]);
    const loading = ref(false);
    // const teacherName = ref("");
    // const schoolName = ref("");
    const router = useRouter();
    const isAndroid = ref(true);
    let gradeName = ref("");
    let className = ref("");
    let gradeClassData = ref(""); // 年级班级数据
    // 是否劢联定制版
    let isML = ref(false);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    // let teachMaterialType = ref("official");
    const preLoadMicroApp = () => {
      return new Promise((resolve) => {
        const firstLoadPPT = store.state.firstLoadPPT;
        loading.value = true;
        if (!firstLoadPPT) {
          loading.value = false;
          resolve();
          return;
        }
        setTimeout(() => {
          loadPPT().then(() => resolve());
        }, 800);
      });
    };
    const loadPPT = () => {
      return new Promise((resolve) => {
        console.time("时间评估 首次预加载");
        if (!window.qiankunStarted) {
          window.qiankunStarted = true;
        }
        const env = JSON.stringify(process.env.RELEASE_ENV);
        let entryUrl = config[servers.pptist];
        if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
          entryUrl = localStorage.entryUrl;
        }

        let isLoadMicroApp = false;
        eventCenter.sub("teachPptPC-preloadOk", () => {
          eventCenter.send(
            event.COMMON_UNLOAD_MICRO_APP,
            {
              app: "teachPptPC",
            },
            (result) => {
              result && logger.info("卸载完成");
              !result && logger.info("卸载失败");
              loading.value = false;
              resolve();
            }
          );
          isLoadMicroApp = true;
          store.commit(MutationNames.CHANGE_FIRSTLOADPPT, false);
          console.timeEnd("时间评估 首次预加载");
        });
        eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
          app: "teachPptPC",
          el: "#ppt-preload",
          entry: entryUrl,
          args: {
            token: store.state.token,
            appName: "teachPptPC",
            preload: true,
          },
          configuration: { $$cacheLifecycleByAppName: true },
        });

        setTimeout(() => {
          if (!isLoadMicroApp) {
            store.commit(MutationNames.CHANGE_FIRSTLOADPPT, true);
          }
        }, 3000);
      });
    };

    onBeforeMount(async () => {
      isML.value = store.state.isML;
      if (store.state.loginType === "account") {
        store.commit(MutationNames.CHANGE_LOGINBTNTXT, true);
      }
    });

    onMounted(async () => {
      // await getKefuWechatQRCode().then((res) => {
      //   getResourceEmptyUrl.value = res.data.qrcode_url;
      //   store.commit(
      //     MutationNames.CHANGE_PCREQUESTEMPTYQRCODEURL,
      //     getResourceEmptyUrl.value
      //   );
      // });
      // getData();
      // store.commit(MutationNames.CHANGE_PCREQUESTEMPTYQRCODEURL, res.data.qrcode_url);
      if (isAndroid.value) {
        preLoadMicroApp().then(() => {
          if (isAndroid.value) {
            window.WebViewJavascriptBridge.callHandler(
              "pptPreloadSuccess", // native中注册时的key值
              null // native中注册方法中的传入的数据
            );
          }
        });
      }
    });
    const compareVersion = (v1, v2) => {
      // -1|v2大于v1；0|相同；1|v2小于v1
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);

      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);

        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    };
    onActivated(() => {
      // this.reload();
      // location.reload();
    });
    const goToCoursewareLibrary = () => {
      router.push({
        path: "/coursewareLibrary",
      });
    };
    const goToHomework = () => {
      localStorage.setItem("fastClassWork", JSON.stringify({}));
      router.push({
        path: "/fastClassWork",
      });
    };
    const goToTeachingMaterial = () => {
      deleteContextInfo(pageTableContextMap.MATERIAL_LIST);
      if (
        moduleName.value === "materialOfficial" ||
        moduleName.value === "characteristicClassroom"
      ) {
        store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      }
      router.push({
        path: "/teachMaterial",
        // path: "/teachMaterialCartoon",
        query: { teachMaterialType: "official" },
      });
    };
    const goToCharacteristicClassroom = () => {
      deleteContextInfo(pageTableContextMap.MATERIAL_LIST);
      if (
        moduleName.value === "materialOfficial" ||
        moduleName.value === "characteristicClassroom"
      ) {
        store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      }
      router.push({
        path: "/teachMaterial",
        // path: "/teachMaterialCartoon",
        query: { teachMaterialType: "characteristicClassroom" },
      });
    };
    const goToMyCourseWare = () => {
      deleteContextInfo(pageTableContextMap.MINE_COURSEWARE);
      if (moduleName.value === "courseWare") {
        store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      }
      router.push({
        // path: "/teachCourseWareCartoon",
        path: "/courseWare",
      });
    };
    // 向electron发送下课状态
    const toElectronFinishLessonInfo = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        logger.info(
          "向electron发送信息摄像头rtsp地址",
          store.state.monitorCameraIp
        );
        // start-camera 必须在版本大于等于1.3.0的版本
        const iscompare = compareVersion(store.state.version, "1.3.0");
        if (iscompare >= 0) {
          ipcRenderer.sendToHost("finish-lesson"); // 向原生发送token信息
        }
      }
    };
    const goToHome = () => {
      confirmQuitFlag.value = false;
      clearInterval(store.state.screenshotTimer);
      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
      // 重制上下课状态
      store.commit(MutationNames.CHANGE_LESSONSTART, true);
      store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
      store.commit(MutationNames.CHANGE_GRADENUMBER, "");
      store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
      store.commit(MutationNames.CHANGE_SCHOOL_ID, undefined);
      // 通知electron下课
      toElectronFinishLessonInfo();
      router.push({
        path: "/login",
      });
    };
    const cancelQuit = () => {
      confirmQuitFlag.value = false;
    };
    const goToMusicAppreciation = () => {
      router.push({
        path: "/appreciateHome",
      });
    };
    // 班级切换
    const classChange = () => {
      isShow.value = true;
    };
    const btnClose = () => {
      logger.info("gradeName-->", store.state.gradeName);
      logger.info("className-->", store.state.className);
      logger.info("selGrade-->", selGrade.value);
      logger.info("selClass-->", selClass.value);
      selGrade.value = store.state.gradeName;
      selClass.value = store.state.className;
      isShow.value = false;
    };
    const changeGradeName = (gradeItem) => {
      // 根据选择的年级取对应的班级

      const classData = gradeClassData.value.filter(
        (item) => item.grade === gradeItem.value
      );
      const classArray = classData[0].class;
      let navClassArray = [];
      for (let j = 0; j < classArray.length; j++) {
        const classNum = classArray[j].school_class_id;
        const className = classArray[j].class_name;
        navClassArray.push({
          value: classNum,
          label: className,
        });
      }
      classOptions.value = navClassArray;
      selClass.value = classOptions.value[0].value;
      selGradeName.value = gradeItem.label;
      selClassName.value = classOptions.value[0].label;
    };
    const changeClassName = (classItem) => {
      selClass.value = classItem.value;
      selClassName.value = classItem.label;
    };
    const centain = () => {
      isShow.value = false;
      store.commit(MutationNames.CHANGE_SCHOOLCLASSID, selClass.value);
      if (selGradeName.value) {
        gradeNum.value = selGradeName.value;
        store.commit(MutationNames.CHANGE_GRADENUMBER, gradeNum.value);
      }
      if (selClassName.value) {
        classNum.value = selClassName.value;
        store.commit(MutationNames.CHANGE_CLASSNUMBER, classNum.value);
      }
      store.commit(MutationNames.CHANGE_CLASSNAME, selClass.value);
      store.commit(MutationNames.CHANGE_GRADENAME, selGrade.value);
      store.commit(MutationNames.CHANGE_IFSHOW, isShow.value);
    };

    const goToMusicTheory = () => {
      router.push({
        path: "/musicKnowLedgeHome",
      });
    };
    const goToGameCenter = () => {
      router.push({
        path: "/gameCenter",
      });
    };
    const goToStaff = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToExample = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goLeft = () => {
      const elem = document.getElementById("Middle");
      elem.scrollLeft += 2000;
    };
    const goRight = () => {
      const elem = document.getElementById("Middle");
      elem.scrollLeft -= 2000;
    };
    const schoolPageUrl = ref();
    const goToSchoolPlatform = () => {
      let env = process.env.RELEASE_ENV ? process.env.RELEASE_ENV : "devp";
      env = env === "prod" ? "" : env;
      // schoolPageUrl.value =
      //   "http://127.0.0.1:8000/skip.html?" +
      //   localStorage.getItem("token") +
      //   "&" +
      //   env;
      schoolPageUrl.value =
        config[servers.school] +
        "/skip.html?" +
        localStorage.getItem("token") +
        "&" +
        env;
      console.log(schoolPageUrl.value);
      const { shell } = window.require("electron");
      shell.openExternal(schoolPageUrl.value);
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.on("goLogin", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        confirmQuitFlag.value = true;
      });
      // ipcRenderer.on("picOssUrl", handleAddLessonProcessData);
    }
    return {
      loading,
      // teacherName,
      // schoolName,
      confirmQuitFlag,
      goToTeachingMaterial,
      goToCoursewareLibrary,
      goToHome,
      goToMusicAppreciation,
      goToMusicTheory,
      goToGameCenter,
      goToHomework,
      goToStaff,
      goToCharacteristicClassroom,
      goLeft,
      goRight,
      goToExample,
      cancelQuit,
      goToMyCourseWare,
      getResourceEmptyUrl,
      classChange,
      isShow,
      // mask,
      close,
      btnClose,
      options,
      value,
      options_next,
      value_next,
      centain,
      gradeNum,
      classNum,
      changeGradeName,
      changeClassName,
      getLable,
      getLableNext,
      getIndexValue,
      getClassData,
      getDataGrade,
      chineseNum,
      gradeName,
      className,
      getSchoolClassId,
      gradeOptions,
      classOptions,
      selGrade,
      selClass,
      isML,
      moduleName,
      goToSchoolPlatform,
      schoolLogo,
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.id === "item0" ? el.click() : null;
      },
    },
    focusNext: {
      mounted(el) {
        el.id === "items0" ? el.click() : null;
      },
    },
  },
});
